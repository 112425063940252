<template>
    <div class="notable">
        <div
            v-masonry
            class="notable-list"
            transition-duration=".3s"
            item-selector=".item"
            :gutter="30"
            :fit-width="true"
            :origin-top="false"
            :destroy-delay="2000"
        >
            <template v-for="widget in notable">
                <spotlight-widget
                    v-if="isNotableReady && widget.type.name == 'Single'"
                    :key="widget.notable_id + '-' + widget.id"
                    v-masonry-tile
                    class="item"
                    :image="widget.files.length ? widget.files[0].url : ''"
                    :color="widget.theme.theme_color || '#1C1C1E'"
                    :title="widget.name"
                    :sub-title="widget.description"
                    :sub-title-top="widget.theme.id != 1"
                    :category="widget.category.name"
                    :notable-id="widget.widget_reference"
                    :entity="widget.content[0]"
                />
                <list-widget
                    v-if="isNotableReady && (widget.type.name == 'Group')
                        && widget.category.name == 'Topics'
                        || isNotableReady && (widget.type.name == 'Group')
                        && widget.category.name == 'Users'
                    "
                    :key="widget.id + '-' + widget.notable_id"
                    v-masonry-tile
                    :is-group="widget.category.name == 'Users'"
                    class="item"
                    :title="widget.name"
                    :sub-title="widget.description"
                    :entity="widget"
                >
                    <template v-for="(item, index) in widget.content">
                        <topic-slot
                            v-if="widget.category.name == 'Topics'"
                            slot="list"
                            :key="'topic' + '-' + item.id"
                            :topic="item"
                            @toggled-follow="(is_following) => widget.content[index].is_following = is_following"
                        />
                        <user-slot
                            v-if="widget.category.name == 'Users'"
                            slot="list"
                            :key="'user' + '-' + item.id"
                            :user="item"
                            @toggled-follow="(is_following) => widget.content[index].is_following = is_following"
                        />
                    </template>
                </list-widget>
                <carousel-widget
                    v-if="isNotableReady && widget.type.name == 'Group' && (widget.category.name == 'Memos' || widget.category.name == 'Boards')"
                    :key="widget.id + '&' + widget.notable_id"
                    v-masonry-tile
                    class="item"
                    :title="widget.name"
                    :sub-title="widget.description"
                    :items="widget.content"
                    :component-as="widget.category.name == 'Memos' ? 'memo-slot' : 'board-slot'"
                    :notable-reference="widget.widget_reference"
                    :widget-type="widget.category.name"
                />
            </template>
        </div>
        <fullscreen-loader v-if="!isNotableReady" />
        <div v-if="isNotableReady && notable.length == 0" class="not-found-memod-list">
            <div class="icon">
                <img :src="noMemosFoundMessage.icon">
            </div>
            <h5>{{ noMemosFoundMessage.title }}</h5>
            <p>{{ noMemosFoundMessage.text }}</p>
        </div>
    </div>
</template>

<script>
import { format as dateFnsFormat } from "date-fns";
import { sortBy, isEmpty } from "lodash";

export default {
    name: "Notable",
    components: {
        SpotlightWidget: () => import(/* webpackChunkName: "spotlight-widget" */ "./widgets/spotlight.vue"),
        ListWidget: () => import(/* webpackChunkName: "list-widget" */ "./widgets/list.vue"),
        CarouselWidget: () => import(/* webpackChunkName: "carousel-widget" */ "./widgets/carousel.vue"),
        TopicSlot: () => import(/* webpackChunkName: "topic-slot" */ "./components/topic-slot.vue"),
        UserSlot: () => import(/* webpackChunkName: "topic-slot" */ "./components/user-slot.vue"),
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue")
    },
    data() {
        return {
            isNotableReady: false,
            notable: [],
            noMemosFoundMessage: {
                icon: require("@assets/img/icons/feed.svg"),
                title: "Uh oh, nothing to see here.",
                text: "Please try again later. You can also browse Memos on the Following feed"
            }
        }
    },
    computed: {
        hasNotableDate() {
            return !isEmpty(this.$route.query.notable_date);
        },
        today() {
            if (this.hasNotableDate) {
                // eslint-disable-next-line camelcase
                const { query: { notable_date } } = this.$route;

                // eslint-disable-next-line camelcase
                return notable_date
            }

            return dateFnsFormat(new Date(), "yyyy-MM-dd");
        }
    },
    watch: {
        "$route": {
            immediate: true,
            handler(destinationRoute) {
                if (destinationRoute.name === "landing") {
                    this.getNotable();
                }

                if (destinationRoute.name === "web-app-home-notable") {
                    this.isNotableReady = false;
                    this.notable = [];
                    this.getNotable();
                }
            }
        }
    },
    methods: {
        async getNotableReference() {
            return axiosV2.get(`/notable?q=(notable_date:${this.today})`).then(({ data }) => {
                return sortBy(data, ["order"]);
            }).catch((error) => {
                console.log(error.response.data.message);
            })
        },
        async getNotable() {
            const notableReference = await this.getNotableReference();

            const getWidgets = () => new Promise((resolve, reject) => {
                const widgetsData = notableReference.map((notable) => {
                    return axiosV2.get(`notable-widget/${notable.entities.widget_reference}?light=true`).then(({ data }) => {
                        return { notable_id: notable.id, ...data };
                    }).catch((error) => {
                        throw new Error(error);
                    });
                });

                Promise.allSettled(widgetsData).then(resolve).catch(reject);
            });

            getWidgets().then((result) => {
                this.notable = result.filter((promise) => promise.status === "fulfilled").map((promise) => promise.value);
                this.isNotableReady = true;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.notable {
    .widget {
        margin-bottom: 30px;
    }

    .notable-list {
        margin: 0 auto;
    }

    .loader {
        position: relative;
        background-color: transparent;
        height: 200px;

        /deep/ img {
            width: 100px !important;
        }
    }

    .not-found-memod-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 230px;
        margin: 0 auto;
        margin-top: 80px;
        text-align: center;

        .icon {
            width: 72px;
            height: 72px;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            img {
                width: 37px;
            }
        }

        h5 {
            font-size: 18px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            color: rgba(155, 155, 162, 0.6);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}
</style>
